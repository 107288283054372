<template>
  <v-container class="my-10">
    <v-row align="center" justify="center">
        <v-col cols="12"></v-col>
      <v-col cols="12" md="6" v-for="(i, item) in images" :key="item">
        <v-img :src="i.url" @click="goto(i)"></v-img>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      images: [
        { url: require("@/assets/menu/menupwbusa.png"), link: "/powerball" },
        { url: require("@/assets/menu/menumega.png"), link: "/megamillions" },
        { url: require("@/assets/menu/menueurojack.png"), link: "/eurojackpot" },
        { url: require("@/assets/menu/menupwbaus.png"), link: "/powerBallAus" },
        { url: require("@/assets/menu/menuminilotto.png"), link: "/minilotto" },
        { url: require("@/assets/menu/menulotlao.png"), link: "/lotterylao" },
      ],
    };
  },
  methods: {
    goto(val) {
        console.log('val',val);
        // link
        this.$router.push({ path: val.link }).catch(() => {});
    },
  },
};
</script>